/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';

import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import Launch from 'views/Launch';

const HomePage = () => {
  return (
    <WithLayout
      component={Launch}
      layout={Main}
    />
  )
};

export default HomePage;
